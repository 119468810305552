<template>
	<div class="hello">
		<div class="login_header">
			<div class="login_header_one" @click="gologin(1)" v-if="userInfo==''">
				<span>登录/注册</span>
			</div>
			<div class="login_header_three" v-else>
				<div class="login_header_three_list" style="line-height: 25px;" @click="gologin(2)">
					<span>退出登录</span>
				</div>
				<div class="login_header_three_list" style="padding-right: 20px;" @click="gonext(9)">
					<img src="../assets/icon4.png" alt="">
					<span>购物车</span>
					<span class="cartnumber" v-if="carttotal>0">({{carttotal}})</span>
				</div>
				<div class="login_header_three_list" style="padding-right: 20px;" @click="gonext(10)">
					<img style="border-radius: 50%;" :src="userInfo.head_img" alt="">
					<span>{{userInfo.user_nickname}}个人中心</span>
				</div>
			</div>
			<!-- 正常的头部导航 -->
			<div class="login_header_two">
				<div class="login_header_two_content">
					<img class="login_img" src="../assets/pclogo.png" alt="" @click="gonext(1)">
					<div class="login_header_two_right">
						<div class="login_header_two_right_list" :class="{'headercheck':headerindex==1}"
							@click="gonext(1)">
							<span>首页</span>
						</div>
						<div class="login_header_two_right_list" @mouseleave="leave()">
							<span :class="{'headercheck':headerindex==2}" @mouseover="gonext(2)">在线课程</span>
							<img src="../assets/icon1.png" alt="" v-if="erjishow" @mouseover="gonext(2)">
							<img src="../assets/icon2.png" alt="" v-else @mouseover="gonext(2)">
							<ul v-if="erjishow==true" @mouseleave="leave()">
								<li @click.stop="erjiclick(21)" :class="{'headercheck':twiceindex==21}">最新课程</li>
								<li @click.stop="erjiclick(22)" :class="{'headercheck':twiceindex==22}">视频课程</li>
								<li @click.stop="erjiclick(23)" :class="{'headercheck':twiceindex==23}">直播课程</li>
								<li @click.stop="erjiclick(24)" :class="{'headercheck':twiceindex==24}">系列课程</li>
							</ul>
						</div>
						<div class="login_header_two_right_list" @click="gonext(3)">
							<span :class="{'headercheck':headerindex==3}">线下课程</span>
						</div>
						<div class="login_header_two_right_list" @click="gonext(4)">
							<span :class="{'headercheck':headerindex==4}">平台专家</span>
						</div>
						<div class="login_header_two_right_list" @click="gonext(5)">
							<span :class="{'headercheck':headerindex==5}">病例展示</span>
						</div>
						<div class="login_header_two_right_list" @click="gonext(6)">
							<span :class="{'headercheck':headerindex==6}">在线商城</span>
						</div>
						<div class="login_header_two_right_list" @click="gonext(7)">
							<span :class="{'headercheck':headerindex==7}">成为会员</span>
						</div>
						<div class="login_header_two_right_list" @mouseleave="leave()">
							<span :class="{'headercheck':headerindex==8}" @mouseover="gonext(8)">关于我们</span>
							<img src="../assets/icon1.png" alt="" v-if="eerjishow" @mouseover="gonext(8)">
							<img src="../assets/icon2.png" alt="" v-else @mouseover="gonext(8)">
							<ul v-if="eerjishow==true" @mouseleave="leave()">
								<li @click="erjiclick(81)" :class="{'headercheck':twiceindex==81}">平台介绍</li>
								<li @click="erjiclick(82)" :class="{'headercheck':twiceindex==82}">常见问题</li>
								<li @click="erjiclick(83)" :class="{'headercheck':twiceindex==83}">联系我们</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<!-- 商城模块的头部 -->
			<div class="shop_header" v-if="headerindex==6 && shopshow==true">
				<div class="shop_header_content">
					<div class="shop_two_header" v-if="headerindex==6" @mouseenter="opencheck">
						<img src="../assets/icon53.png" alt="">
						<span>全部商品分类</span>
					</div>
					<!-- <img class="shop_header_log" src="../assets/pclogo.png" alt=""> -->
					<div class="shop_header_cart" @click="gocart">
						<img src="../assets/icon51.png" alt="">
						<span>我的购物车({{carttotal}})</span>
					</div>
					<div class="shop_header_search">
						<img src="../assets/icon51.png" alt="">
						<input type="text" placeholder="请输入想要搜索的内容…" v-model="keywords">
						<div class="shop_header_search_btn" @click="searchclick">
							<span>搜索</span>
						</div>
						<div class="shop_header_search_history" v-if="searchtype">
							<div class="shop_header_search_history_list">
								<span>最近搜索</span>
								<img src="../assets/icon54.png" alt="">
							</div>
							<div class="shop_header_search_history_list">
								<span>拔牙钳</span>
								<img style="width: 12px;height: 12px;" src="../assets/icon55.png" alt="">
							</div>
						</div>
					</div>
					<div class="shop_two_sx" v-if="shopsxtype && headerindex==6" @mouseleave="closecheck">
						<div class="shop_two_sx_left">
							<div class="shop_two_sx_left_list" :class="{'text_now':firstid==item.aid}"
								v-for="(item,index) in goodscatlist" :key="item.aid"
								@mouseenter="firstclick(item.aid,item.children)" @click="thirdclick(item.aid)">
								<span>{{item.name}}</span>
								<img src="../assets/icon39.png" alt="">
							</div>
						</div>
						<div class="shop_two_sx_right">
							<div class="shop_two_sx_right_list" v-for="(item,index) in twicelist">
								<p @click="thirdclick(item.aid)">{{item.name}}</p>
								<div class="shop_two_sx_right_list_content">
									<span v-for="(list,thirdindex) in item.children" :key="thirdindex"
										:class="{'text_now':thirdid==list.aid}"
										@click="thirdclick(list.aid)">{{list.name}}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'HelloWorld',
		data() {
			return {
				headerindex: 1, //头部一级状态
				twiceindex: "", //头部二级状态
				searchtype: false, //历史搜索的状态
				shopsxtype: false, //在线商城的商品筛选状态
				userInfo: "", //用户信息
				goodscatlist: [], //商品分类数据
				firstid: 0, //筛选一级的id
				twicelist: [], //二级筛选条件
				twiceid: "", //二级分类的id
				thirdid: "", //三级分类id
				carttotal: 0, //购物车的数量
				erjishow: false,
				eerjishow: false,
				oneurl: "", //获取的当前页面路径
				keywords: "", //搜索关键车
				shopshow: false, //判断在商城页面时点击课程和关于我们的展示状态

				la_id: "",
				nowurl: "",
			}
		},
		mounted() {
			this.oneurl = window.location.href;
			if (localStorage.getItem("userInfo")) {
				this.get_goodscart();
				this.get_user_info();
			}
			this.get_goodscategory(); //获取商品分类
			if (localStorage.getItem("firstid")) {
				this.firstid = localStorage.getItem("firstid");
			}
			if (localStorage.getItem("thirdid")) {
				this.thirdid = localStorage.getItem("thirdid");
			}
			if (localStorage.getItem('headerindex')) {
				this.headerindex = localStorage.getItem('headerindex');
			} else {
				this.headerindex = 1;
			}
			if (localStorage.getItem('twiceindex')) {
				this.twiceindex = localStorage.getItem('twiceindex');
			} else {
				this.twiceindex = "";
			}
			if (this.headerindex == 6) {
				this.shopshow = true;
			}
		},
		created() {
			this.linktab();
		},
		methods: {
			linktab() {
				let goUrl = this.isMobile();
				if (goUrl === 1) {
					//移动端地址
					location = "https://admin.denlike.com/ydh5/#/";
				} else {
					//PC地址
					// location = "https://www.denlike.com/";
				}
			},
			isMobile() {
				let flag = navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				);
				let goUrl = flag ? 1 : 0;
				return goUrl;
			},
			//获取父组件传过来的商品数量
			getcartnumber(num) {
				this.carttotal = num;
			},

			//获取用户信息
			get_user_info() {
				this.$http({
					url: '5c78c4772da97',
					method: 'post',
					data: JSON.stringify({

					})
				}).then((res) => {
					if (res.data.code == 1) {
						this.userInfo = res.data.data;
					}
				});
			},

			//跳转登录,退出登录页面
			gologin(type) {
				if (type == 1) {
					this.$router.push({
						path: '/logIn',
					})
				} else {
					localStorage.clear();
					if (this.oneurl == "https://www.denlike.com/pcchina/#/") {
						window.location.reload()
					} else {
						this.$router.push({
							path: '/'
						})
					}
				}
			},

			//跳转下级页面(一级点击事件)
			gonext(type) {
				if (type == 1) {
					this.headerindex = type;
					localStorage.setItem('headerindex', type);
					localStorage.setItem('twiceindex', "");
					if (this.oneurl == "https://www.denlike.com/pcchina/#/") {
						window.location.reload();
					} else {
						this.$router.push({
							path: "/"
						})
					}
				} else if (type == 2) {
					this.erjishow = true;
					// this.erjishow = !this.erjishow;
				} else if (type == 3) {
					this.headerindex = type;
					localStorage.setItem('headerindex', type);
					localStorage.setItem('twiceindex', "");
					//线下课程
					let pageurl = this.oneurl.split("/#/")[1];
					if (pageurl == "OfflineCourses") {
						window.location.reload();
					} else {
						this.$router.push({
							path: "/OfflineCourses"
						})
					}
				} else if (type == 4) {
					this.headerindex = type;
					localStorage.setItem('headerindex', type);
					localStorage.setItem('twiceindex', "");
					//平台专家
					let pageurl = this.oneurl.split("/#/")[1];
					if (pageurl == "PlattformExperten") {
						window.location.reload();
					} else {
						this.$router.push({
							path: "/PlattformExperten"
						})
					}
				} else if (type == 5) {
					this.headerindex = type;
					localStorage.setItem('headerindex', type);
					localStorage.setItem('twiceindex', "");
					//病例展示
					let pageurl = this.oneurl.split("/#/")[1];
					if (pageurl == "Casepresentation") {
						window.location.reload();
					} else {
						this.$router.push({
							path: "/Casepresentation"
						})
					}
				} else if (type == 6) {
					this.headerindex = type;
					localStorage.setItem('headerindex', type);
					localStorage.setItem('twiceindex', "");
					//在线商城
					let pageurl = this.oneurl.split("/#/")[1];
					if (pageurl == "shopIndex") {
						window.location.reload();
					} else {
						this.$router.push({
							path: "/shopIndex"
						})
					}
				} else if (type == 7) {
					this.headerindex = type;
					localStorage.setItem('headerindex', type);
					localStorage.setItem('twiceindex', "");
					//成为会员
					let pageurl = this.oneurl.split("/#/")[1];
					if (pageurl == "memberVip") {
						window.location.reload();
					} else {
						this.$router.push({
							path: "/memberVip"
						})
					}
				} else if (type == 8) {
					this.eerjishow = true;
					// this.eerjishow = !this.eerjishow;
				} else if (type == 9) {
					this.headerindex = type;
					localStorage.setItem('headerindex', type);
					localStorage.setItem('twiceindex', "");
					localStorage.setItem('headerindex', 6);
					//跳转购物车
					let pageurl = this.oneurl.split("/#/")[1];
					if (pageurl == "ShoppingCart") {
						window.location.reload();
					} else {
						this.$router.push({
							path: "ShoppingCart"
						})
					}
				} else if (type == 10) {
					this.headerindex = type;
					localStorage.setItem('headerindex', type);
					localStorage.setItem('twiceindex', "");
					//个人中心
					localStorage.setItem("oncetype", 0);
					let pageurl = this.oneurl.split("/#/")[1];
					if (pageurl == "about") {
						window.location.reload();
					} else {
						this.$router.push({
							path: "about"
						});
					}
				};
			},

			//鼠标悬停离开时间
			leave() {
				this.erjishow = false;
				this.eerjishow = false;
			},

			//二级点击事件
			erjiclick(index) {
				let pageurl = this.oneurl.split("/#/")[1];
				localStorage.setItem("twiceindex", index);
				if (index == 21) {
					localStorage.setItem('headerindex', 2);
					let newpageurl = pageurl.split("?")[0];
					if (newpageurl == "newcourses") {
						window.location.reload();
					} else {
						this.$router.push({
							path: "newcourses",
							query: {
								type: 1
							}
						});
					}
				} else if (index == 22) {
					localStorage.setItem('headerindex', 2);
					let newpageurl = pageurl.split("?")[0];
					if (newpageurl == "videocourses") {
						window.location.reload();
					} else {
						this.$router.push({
							path: "videocourses",
							query: {
								type: 2
							}
						});
					}
				} else if (index == 23) {
					localStorage.setItem('headerindex', 2);
					let newpageurl = pageurl.split("?")[0];
					if (newpageurl == "livecourses") {
						window.location.reload();
					} else {
						this.$router.push({
							path: "livecourses",
							query: {
								type: 3
							}
						});
					}
				} else if (index == 24) {
					localStorage.setItem('headerindex', 2);
					let newpageurl = pageurl.split("?")[0];
					if (newpageurl == "Latestcourses") {
						window.location.reload();
					} else {
						this.$router.push({
							path: "Latestcourses",
							query: {
								type: 5
							}
						});
					}
				} else if (index == 81) {
					//平台介绍
					localStorage.setItem('headerindex', 8);
					if (pageurl == "PlatformIntroduction") {
						window.location.reload();
					} else {
						this.$router.push({
							path: "PlatformIntroduction"
						});
					}
				} else if (index == 82) {
					//常见问题
					localStorage.setItem('headerindex', 8);
					if (pageurl == "commonProblem") {
						window.location.reload();
					} else {
						this.$router.push({
							path: "commonProblem"
						});
					}
				} else if (index == 83) {
					//联系我们
					localStorage.setItem('headerindex', 8);
					if (pageurl == "contactUs") {
						window.location.reload();
					} else {
						this.$router.push({
							path: "contactUs"
						});
					}
				}
			},

			//搜索点击事件
			searchclick() {
				var url = window.location.href;
				// if(url=="https://www.denlike.com/pcchina/#/shopIndex"){
				// 	this.$router.push({path:"shopSearch"})
				// }else{
				// 	this.$emit('change', this.keywords);
				// }
				this.$emit('change', this.keywords);
			},

			//跳转购物车
			gocart() {
				if (this.userInfo != '') {
					let pageurl = this.oneurl.split("/#/")[1];
					if (pageurl == "ShoppingCart") {
						window.location.reload();
					} else {
						this.$router.push({
							path: "ShoppingCart"
						})
					}
					// this.$router.push({path:"ShoppingCart"})
				} else {
					this.$router.push({
						path: "logIn"
					})
				}
			},

			//打开商品筛选条件
			opencheck() {
				this.shopsxtype = true;
			},

			//关闭商品筛选
			closecheck() {
				this.shopsxtype = false;
			},

			//一级筛选点击事件
			firstclick(id, item) {
				this.firstid = id;
				this.twicelist = item;
				localStorage.setItem("firstid", id);
			},

			//二级的点击事件
			twiceclick(aid) {
				this.$emit('sxclick', aid)
				this.shopsxtype = !this.shopsxtype;
			},

			//三级点击事件
			thirdclick(aid) {
				this.$emit('sxclick', aid);
				this.thirdid = aid;
				localStorage.setItem("thirdid", aid);
				this.shopsxtype = !this.shopsxtype;
				this.$router.push({
					path: "shopSearch",
					query: {
						aid: aid
					}
				});
			},

			//获取商品分类的事件
			get_goodscategory() {
				this.$http({
					url: '62c3b236b9e99',
					method: 'get',
					params: {
						list_rows: "15",
					},
				}).then((res) => {
					if (res.data.code == 1) {
						this.goodscatlist = res.data.data;
						this.firstid = this.goodscatlist[0].aid;
						this.twicelist = this.goodscatlist[0].children;
					}
				});
			},

			//获取购物车列表
			get_goodscart() {
				this.$http({
					url: '62b981f24a040',
					method: 'get',
					params: {},
				}).then((res) => {
					if (res.data.code == 1) {
						this.carttotal = res.data.data.length;
					}
				});
			},
		},
	}
</script>
<style scoped>
	.login_header {
		width: 100%;
		height: 40px;
		background: #F6F6F6;
		cursor: pointer;
	}

	.login_header_one {
		width: 1200px;
		height: 100%;
		margin: 0 auto;
		text-align: right;
		font-size: 12px;
		font-weight: 400;
		color: #333333;
		line-height: 40px;
	}

	.login_header_two {
		width: 100%;
		height: 100px;
		/* height: auto; */
		background-color: #fff;
		border-bottom: 1px solid #eee;
	}

	.login_header_two_content {
		width: 1200px;
		height: auto;
		margin: 0 auto;
		position: relative;
	}

	.shop_two_sx {
		width: 930px;
		overflow: hidden;
		background-color: #fff;
		position: absolute;
		left: 0;
		top: 60px;
		z-index: 999;
	}

	.shop_two_sx_left {
		width: 173px;
		height: 100%;
		float: left;
		/* overflow-y: scroll; */
	}

	.shop_two_sx_left_list {
		width: 100%;
		height: 45px;
		line-height: 45px;
		font-size: 14px;
		font-family: Microsoft YaHei-Regular, Microsoft YaHei;
		font-weight: 400;
		color: #333333;
	}

	.shop_two_sx_left_list span {
		display: block;
		float: left;
		height: 100%;
		margin-left: 20px;
	}

	.shop_two_sx_left_list img {
		width: 6px;
		height: 10px;
		display: block;
		float: right;
		margin-top: 17.5px;
		margin-right: 17.5px;
	}

	.shop_two_sx_right {
		width: 740px;
		height: 100%;
		float: left;
		/* overflow-x: scroll; */
	}

	.shop_two_sx_right_list {
		width: 696px;
		overflow: hidden;
		border-bottom: 1px dashed #BFBFBF;
		margin: 0 auto;
	}

	.shop_two_sx_right_list p {
		font-size: 16px;
		font-family: Microsoft YaHei-Bold, Microsoft YaHei;
		font-weight: bold;
		color: #333333;
		margin-top: 18px;
	}

	.shop_two_sx_right_list_content {
		width: 100%;
		overflow: hidden;
		font-size: 14px;
		font-family: Microsoft YaHei-Regular, Microsoft YaHei;
		font-weight: 400;
		color: #666666;
		margin-bottom: 15px;
	}

	.shop_two_sx_right_list_content span {
		width: auto;
		display: block;
		float: left;
		text-align: center;
		padding: 10px 20px 10px 20px;
		margin-top: 10px;
	}

	.login_img {
		width: 127px;
		height: 56px;
		display: block;
		margin-top: 23px;
		float: left;
	}

	.login_header_two_right {
		width: auto;
		height: auto;
		float: right;
		position: relative;
		z-index: 999;
	}

	.login_header_two_right_list {
		width: 120px;
		text-align: center;
		float: left;
		font-size: 16px;
		font-family: Microsoft YaHei-Regular, Microsoft YaHei;
		font-weight: 400;
		color: #333333;
		position: relative;
	}

	.login_header_two_right_list span {
		line-height: 102px;
	}

	.login_header_two_right_list img {
		width: 12px;
		height: 10px;
		margin-left: 8px;
	}

	.login_header_two_right_list ul {
		width: 120px;
		height: 156px;
		background: #FFFFFF;
		box-shadow: 0px 0px 20px 1px rgba(0, 90, 201, 0.1);
		border-radius: 8px 8px 8px 8px;
		position: absolute;
		left: 0;
		top: 80px;
		list-style: none;
		line-height: 40px;
	}

	.headercheck {
		font-weight: bold;
		color: #EB6A39;
	}

	.login_header_three {
		width: 1200px;
		height: 100%;
		margin: 0 auto;
		text-align: right;
		font-size: 12px;
		font-family: Microsoft YaHei-Regular, Microsoft YaHei;
		font-weight: 400;
		color: #333333;
		line-height: 40px;
	}

	.login_header_three_list {
		width: auto;
		height: 20px;
		float: right;
		border-left: 1px solid #DCDCDC;
		line-height: 20px;
		padding-left: 20px;
		margin-top: 10px;
		position: relative;
	}

	.login_header_three_list img {
		width: 18px;
		height: 18px;
		position: relative;
		top: 4px;
		margin-right: 4px;
	}

	.login_header_three_list:last-child {
		border: none;
	}

	.cartnumber {
		/* width:12px;
		height:12px; */
		/* position: absolute;
		left: 25px;
		top: 0; */
		color: red;
		text-align: center;
		font-size: 14px;
		/* padding: 3px; */
	}

	/* 商城模块的头部 */
	.shop_header {
		width: 100%;
		height: auto;
		background-color: #fff;
	}

	.shop_header_content {
		width: 1200px;
		height: 100%;
		margin: 24px auto;
		position: relative;
	}

	.shop_header_log {
		width: 178px;
		height: 56px;
	}

	.shop_header_cart {
		width: 164px;
		height: 40px;
		background: #FFFFFF;
		border-radius: 8px 8px 8px 8px;
		border: 1px solid #EB6A39;
		text-align: center;
		line-height: 40px;
		float: right;
		margin-top: 8px;
		font-size: 14px;
		font-family: Microsoft YaHei-Regular, Microsoft YaHei;
		font-weight: 400;
		color: #EB6A39;
	}

	.shop_header_cart img {
		width: 16px;
		height: 16px;
		position: relative;
		top: 3px;
		margin-right: 10px;
	}

	.shop_header_search {
		width: 486px;
		height: 40px;
		background: #FFFFFF;
		border-radius: 8px 8px 8px 8px;
		border: 1px solid #EB6A39;
		float: right;
		margin-right: 32px;
		margin-top: 8px;
		position: relative;
	}

	.shop_header_search img {
		width: 14px;
		height: 14px;
		display: block;
		float: left;
		margin-top: 13px;
		margin-left: 13px;
	}

	.shop_header_search input {
		width: 300px;
		height: 100%;
		outline: none;
		border: none;
		font-size: 12px;
		font-family: Microsoft YaHei-Regular, Microsoft YaHei;
		font-weight: 400;
		color: #BFBFBF;
		text-indent: 20px;
	}

	.shop_header_search_btn {
		width: 90px;
		height: 40px;
		background: #EB6A39;
		border-radius: 8px 8px 8px 8px;
		border: 1px solid #EB6A39;
		font-size: 14px;
		font-family: Microsoft YaHei-Regular, Microsoft YaHei;
		font-weight: 400;
		color: #FFFFFF;
		text-align: center;
		position: absolute;
		right: 0;
		top: 0;
		line-height: 40px;
	}

	.shop_header_search_history {
		width: 446px;
		height: 306px;
		background: #FFFFFF;
		box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
		position: relative;
		z-index: 9999;
		position: absolute;
		left: 20px;
		top: 42px;
	}

	.shop_header_search_history_list {
		width: 90%;
		height: auto;
		margin: 0 auto;
		clear: both;
	}

	.shop_header_search_history_list span {
		font-size: 14px;
		font-family: Microsoft YaHei-Regular, Microsoft YaHei;
		font-weight: 400;
		color: #999999;
		display: block;
		float: left;
		line-height: 40px;
	}

	.shop_header_search_history_list img {
		width: 14px;
		height: 16px;
		display: block;
		float: right;
	}

	.shop_two_header {
		width: 172px;
		height: 60px;
		background: #EB6A39;
		float: left;
		font-size: 14px;
		font-family: Microsoft YaHei-Regular, Microsoft YaHei;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 59px;
		margin-top: 0px;
	}

	.shop_two_header img {
		width: 16px;
		height: 16px;
		display: block;
		float: left;
		margin-left: 16px;
		margin-top: 21px;
		margin-right: 10px;
	}

	.text_now {
		color: #fff;
		background-color: #EB6A39;
	}
</style>
